import { Application } from "stimulus"

import Rails from '@rails/ujs';

const application = Application.start()


// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
