import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper/bundle';
import Rails from '@rails/ujs';

import 'swiper/css/bundle';

const imageDuration = 4000;
const videoDelay = 3000; // how much earlier the videoanimation should start before the video ends

export default class extends Controller {

  connect() {
    // console.log("Swiper controller connected");

    // const elements = this.element.querySelectorAll('.item');
    // var firstElement = elements[0];

    // //this.addVideoEndedListener();
    // firstElement.classList.add('active');

    // this.handleElement(firstElement);

  }

  nextSlide() {
    console.log("Send alive signal and check for updates");
    //this.sendAliveSignal();
    //this.checkForUpdate();

    const overlay = document.querySelector('#transition-overlay');
    var current = document.querySelector('.active');
    var next = current.nextElementSibling;

    var handleElement = this.handleElement;

    var tl = gsap.timeline();
    tl.from(overlay, {
      duration: 3,
      top: "100%",
      left: "100%",
      ease: "power2.inOut",
      onComplete: function () {
        // Start next element
        handleElement(next);
      }
    });
  }
  

  handleElement(element) {
    console.log("Handle element:" + element)
    if (element.tagName == "VIDEO") {
      var duration = Math.floor(element.getAttribute('data-duration'));
      element.load();

      var nextSlide = this.nextSlide;

      element.play();
      setTimeout(() => {
        nextSlide();
      }, (duration * 1000) - videoDelay);

    } else if (element.tagName == "IMG") {
      console.log("Element is a image");
      setInterval(nextSlide(), imageDuration);
    }
  }

  addVideoEndedListener() {
    let videos = this.element.querySelectorAll("video");

    videos.forEach((video, i) => {
      // Event listener callback function
      const handleVideoEnded = () => {

        if (this.currentTime > this.duration - videoDelay) {
          // this.nextSlide();
          this.devLog("Video ended...")
          video.load();
        }

      };

      // Add event listener
      video.addEventListener("timeupdate", handleVideoEnded);
    });
  }

  sendAliveSignal() {
    const token = "bJc9rgcg<oPQBHf";
    const requestUrl = "/update_display/:key/:id/:last_used_at";
    let displayCode = this.element.getAttribute("data-displaycode")

    Rails.ajax({
      url: requestUrl.replace(":key", token)
        .replace(":id", displayCode)
        .replace(":last_used_at", (Date.now() / 1000)),
      type: 'GET',
      success: function (result) {
        if (result["error"]) {
          console.log("Error: " + result["error"]);
        }
      }
    })
  }

  checkForUpdate() {
    const requestUrl = "/update_display/:key/:id/:last_used_at";
    let displayCode = this.element.getAttribute("data-displaycode")

    Rails.ajax({
      url: '/check_update/:displayCode'.replace(':displayCode', displayCode),
      type: 'GET',
      data: '',
      success: function (response) {
        if (response.success.lastUpdateAt > pageLoadedAt) {
          location.reload();
        }
      },
      error: function (error) {
        console.error('Error:', error);
      }
    });
  }



  devLog(string) {
    var currentdate = new Date();
    var datetime = currentdate.getDate() + "/"
      + (currentdate.getMonth() + 1) + "/"
      + currentdate.getFullYear() + " @ "
      + currentdate.getHours() + ":"
      + currentdate.getMinutes() + ":"
      + currentdate.getSeconds() + " | ";

    if (rails_env != "production") {
      console.log(datetime, string)
    }
  };

  disconnect() {
    this.devLog("disconnect")
    this.swiper.destroy();
  }

}