import { Controller } from "stimulus"
import Rails from '@rails/ujs';

import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      filter: '.disabled'
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newIndex + 1)

    Rails.ajax({
      url: window.location.href + '/' + this.data.get("url").replace(":id", id),
      type: 'PATCH',
      data: data,
      success: function(result) {
        if (result["error"]) {
          console.log("Error: " + result["error"]);
        }
      }
    })
  }
}
