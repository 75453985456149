import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

// Connects to data-controller="webview"
export default class extends Controller {

  connect() {
    // Check if displayCode was already set before
    if (localStorage.getItem("displayCode")) {
      this.loadDisplayCode(localStorage.getItem("displayCode"))
    } else {
      this.initCodeForm();
    }

    this.initDevMenu();
  }

  initCodeForm() {
    let form = this.element.querySelector('form');

    form.addEventListener('submit', (e) => {
      e.preventDefault();
      const data = Object.fromEntries(new FormData(form).entries());
      var code = data.displayCode;
      this.loadDisplayCode(code);
    })
  }

  loadDisplayCode(displayCode) {
    if (displayCode) {
      this.checkSubmitCode(displayCode, (is_valid) => {
        if (is_valid) {
          localStorage.setItem("displayCode", displayCode);
          window.location = "/webview?id=" + displayCode;
        } else {
          console.log()
          alert("Der eingegebene Code wurde nicht gefunden.");
          localStorage.removeItem("displayCode", displayCode);
        }
      })
    }
  }

  checkSubmitCode(displayCode, callback) {
    Rails.ajax({
      url: '/verify/:displayCode'.replace(':displayCode', displayCode),
      type: 'GET',
      data: '',
      success: function (response) {
        callback(true);
      },
      error: function (error) {
        console.error('Error:', error);
        callback(false);
      }
    });
  }


  /// 
  /// DEV MENU
  ///


  initDevMenu() {
    document.addEventListener("keyup", (event) => {
      var keyCode = event.keyCode;
      if (keyCode == 13) {
        this.openDevMenu();
      } else if (keyCode == 4 || keyCode == 9) {
        this.closeDevMenu();
      }
    });
  }

  openDevMenu() {
    let menu = this.element.querySelector('webview-data');
  }

  closeDevMenu() {

  }
}
